@import '../assets/scss/variables.scss';

#ListItem {
    background-color: #fff;
}

#Loading {
    position: absolute;
    width: calc(100vw - 305px);
    height: calc(100vh - 150px);
    z-index: 999999999999;
    background-color: rgba($color: #fff, $alpha: .95);

    &.full {
        width: 100vw !important;
        height: 100vh !important;
    }

    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 4px solid $primary;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $primary transparent transparent transparent;
    }

    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }

    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }

    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

}

#CodeInput {
    input {
        width: 60px;
        height: 60px;
        font-size: 16px;
        text-align: center;
        font-weight: 700;
        border-radius: 16px;
        box-shadow: 0px 2px 20px rgba(117, 34, 119, 0.04);
    }
}

#Steps {
    .bubble {
        width: 10px;
        height: 10px;
        margin-left: 5px;
        border-radius: 45px;
        background-color: rgba($primary, .4);
    }

    .fill {
        background-color: rgba($primary, 1);
    }

    .long {
        width: 38.51px;
        background-color: rgba($primary, 1);
    }
}

#ButtonGroup {
    border-radius: 8px;

    .button {
        width: 32px;
        height: 30px;
        border-radius: 4px;

        &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
}

#Table {
    overflow: scroll;

    td {
        overflow: hidden;
        max-width: 150px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

#Alert {
    border-radius: 8px;
    padding: 10px 14px;

    &.warning {
        color: #804E00 !important;
        background: #FFFAF2;
    }

    &.danger {
        color: $danger !important;
        background: rgba($danger, .1);
    }

    &.success {
        color: $success !important;
        background: rgba($success, .1);
    }
}

#Tabs {
    &.card {
        border: 1.5px solid #D0D5DD;
        border-radius: 12px;
        margin-bottom: 16px;
        display: inline-flex;

        .tab {
            // background: #fff;
            font-size: 13px;
            border-right: 1.5px solid #D0D5DD;

            &:first-child {
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
            }

            &:last-child {
                border-right: 0px;
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
            }

            &.active {
                background: #E3E8E9;
            }
        }
    }

    &.line {
        display: flex;

        .tab {
            background: #fff;
            font-size: 13px;

            &.active {
                border-bottom: 2px solid $primary;
            }
        }
    }
}

#AmountCurrency {
    .ant-select-selector {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-right: 0px !important;
    }

    input.ant-input {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
}

#Status {

    &.warning {
        color: $warning;
        // border: 1px solid rgba($warning, .25);
        background-color: rgba($warning, .15);
    }

    &.danger {
        color: $danger;
        // border: 1px solid rgba($danger, .15);
        background-color: rgba($danger, .1);
    }

    &.success {
        color: $success;
        // border: 1px solid rgba($success, .15);
        background-color: rgba($success, .1);
    }

    .dot {
        &.warning {
            background-color: $warning;
        }

        &.danger {
            background-color: $danger;
        }

        &.success {
            background-color: $success;
        }
    }
}

.material-symbols-outlined.fill {
    font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
}