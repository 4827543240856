@include media('<tablet') {
    .site-layout {
        margin-left: 0px;
    }

    .container {
        width: 100vw !important;
        height: 100vh !important;
        margin: 0px !important;
        border-radius: 0px !important;
    }

    .sider-overlay {
        display: block;
        background-color: $dark2;
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
        z-index: 90;
    }

    .ant-layout-sider {
        // display: none !important;
        width: 83vw !important;
        min-width: 83vw !important;
        max-width: 83vw !important;
        z-index: 99;
    }

    .ant-layout-header {
        margin-top: 0px !important;
        border-radius: 0px !important;
        background-color: $dark !important;

        #header_desktop {
            display: none !important;
        }

        #header_mobile {
            height: 64px;
            display: flex !important;
            background-color: $dark;
        }
    }

    .ant-drawer-content-wrapper {
        width: 100vw !important;
    }

    #businessScroll {
        display: flex;
        overflow-x: scroll;

        >div {
            // min-width: 120px;
            max-width: 1200px;
            margin-right: 5px;
            margin-bottom: 0px;
        }
    }
}