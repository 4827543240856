.ant-steps-vertical>.ant-steps-item .ant-steps-item-content {
    min-height: 70px !important;
}

.ant-layout {
    background: $dark !important;
}

.ant-layout-sider {
    background: $dark !important;
    width: 250px !important;
    min-width: 250px !important;
    max-width: 250px !important;
    z-index: 99;

    .nav-item {
        // display: block;
        color: rgba(#fff, .7);
        margin-top: 12px;
        border-radius: 6px;

        &:hover,
        &.active {
            color: rgba(#fff, 1) !important;
            background-color: $primary;
        }

        &.active {
            color: $primary;
            font-weight: 500;
        }
    }
}

.ant-layout-header {
    border-radius: 20px !important;
    padding-inline: 1.25rem !important;
}

.site-layout {
    margin-left: 250px;
}

.ant-switch {
    background-color: #EAECF0;
}

.ant-input,
.ant-input-affix-wrapper-lg {
    // border: 1px solid #D0D5DD;
    // box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    padding: 9px 11px;
    font-size: 15px;

    &.ant-input-lg-,
    &.ant-input-password-large- {
        padding: 9px 14px;
        height: 44px;
    }
}

.ant-btn {
    box-shadow: none;
    font-weight: 500;

    &.ant-btn-lg {
        height: 44px;
        font-size: 14px;
    }

    &.ant-btn-primary {
        background-color: $primary;
    }
}

.ant-form-item-explain-error,
.ant-form-item-explain {
    font-size: 12px !important;
}

.ant-form-item-label {
    label {
        color: #344054 !important;
        font-weight: 500 !important;
    }

    .ant-form-item-required::before {
        display: none !important;
    }
}

.ant-select {
    .ant-select-selector {
        height: 42.3px !important;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
        line-height: 41px !important;
    }
}

.ant-select-dropdown {
    z-index: 9999;
}

.ant-drawer {
    .ant-drawer-header {
        border: none;
        margin-top: 40px;
        padding-left: 40px;
        display: none;
    }

    .ant-drawer-body {
        padding: 50px 40px 40px 40px;
    }
}

.ant-notification-notice-message {
    margin-bottom: 0px !important;
}

.ant-modal {
    .ant-modal-title {
        text-align: center;
    }

    .ant-modal-confirm-content {
        max-width: 100% !important;
    }

    .ant-modal-content {
        padding: 0;
    }

    .ant-modal-header,
    .ant-modal-body {
        padding: 20px 24px;
    }

    .ant-modal-header {
        border-bottom: 1px solid #eee;
    }
}

.ant-tooltip-inner {
    background-color: #003A3A !important;
}

.ant-input-group-addon {
    font-size: 13px !important;
}

.ant-collapse {
    border: 0px !important;
    background: none !important;

    .ant-collapse-item {
        border: 0 !important;
        margin-bottom: 15px;

        .ant-collapse-header {
            background-color: #fff;
            border-radius: 12px !important;
            border: 1px solid #EAECF0;
        }

        .ant-collapse-content {
            border: 0;
            background-color: #fff;
            border-bottom-left-radius: 12px !important;
            border-bottom-right-radius: 12px !important;
        }

        &.ant-collapse-item-active {
            .ant-collapse-header {
                border-top-left-radius: 12px !important;
                border-top-right-radius: 12px !important;
                border-bottom-left-radius: 0px !important;
                border-bottom-right-radius: 0px !important;
                border-bottom: 0px;
            }

            .ant-collapse-content {
                border: 1px solid #EAECF0;
                border-top: 0px;
            }
        }
    }
}